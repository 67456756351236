// Import our custom CSS
import "../scss/styles.scss";

// Import only the Bootstrap components we need
import { Popover } from "bootstrap";

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]').forEach((popover) => {
  new Popover(popover);
});

import SmoothScrollbar from "smooth-scrollbar";
import ScrollTriggerPlugin from "./vendor/smooth-scrollbar/ScrollTriggerPlugin";
import SoftScrollPlugin from "./vendor/smooth-scrollbar/SoftScrollPlugin";
import gsap from "gsap";
import { preloadImages, calcDrawImage } from "./utills";

// GSAP ScrollTrigger & Soft Edges plugin for SmoothScroll
SmoothScrollbar.use(ScrollTriggerPlugin, SoftScrollPlugin);

// Init smooth scrollbar
const view = document.getElementById("view-main");
const sticky = document.getElementById("header-scroll");
const menu = document.getElementById("drop-menu");
const toggle = document.getElementById("dropdown-toggle");

var lastScrollTop = 0;
var scrollHeight = window.innerHeight * 1;
const isMobile = window.innerWidth <= 768;

const dampingValue = isMobile ? 0.1 : 0.075;
const scrollbar = SmoothScrollbar.init(view, {
  renderByPixels: false,
  damping: 0.075,
});

scrollbar.addListener(function (status) {
  var offset = status.offset;
  var total = offset.y + "px";
  sticky.style.top = offset.y + "px";
  sticky.style.left = offset.x + "px";

  var currentScroll = offset.y || document.documentElement.scrollTop;
  if (currentScroll > lastScrollTop && currentScroll > scrollHeight) {
    sticky.classList.add("hidden");
    menu.classList.remove("show");
    toggle.classList.remove("show");
  } else if (currentScroll < lastScrollTop) {
    sticky.classList.remove("hidden");
  }
  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
});

// Tranform animation on reveal

function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  elem.style.transform = "translate(" + x + "px, " + y + "px)";
  elem.style.opacity = "0";
  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto",
    }
  );
}

function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
}

gsap.defaults({
  toggleActions: "restart pause resume none",
  markers: true,
});

document.addEventListener("DOMContentLoaded", function () {
  //   // gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    gsap.to(
      "elem",
      {
        scrollTrigger: {
          trigger: "elem",
          // markers: true,
          scrub: 1,
          start: function () {
            animateFrom(elem);
          },
          end: function () {
            hide(elem);
          },
        },
        delay: 0.275,
        ease: "power1.inOut",
      }
      // trigger: elem,
      // markers: true,
      // onEnter: function () {
      //   animateFrom(elem);
      // }
      // onEnterBack: function () {
      //   animateFrom(elem, -1);
      // },
      // onLeave: function () {
      //   hide(elem);
      // },
      // assure that the element is hidden when scrolled into view
    );
  });
  // });
});

// Spiral rotate animation on scroll
(function () {
  gsap.to(".spiral", {
    scrollTrigger: {
      trigger: ".spiral",
      start: "top center",
      // markers: "true",
      scrub: 1,
      pin: "true",
      toggleActions: "restart pause reverse pause",
    }, // start animation when ".box" enters the viewport
    // y: -50,
    rotate: 90,
    opacity: 0.5,
    duration: 0.375,
  });
})();
